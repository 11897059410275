
export default {
  head() {
    return this.$seoMeta.head(this, {
      siteName: this.$t('siteName'),
      siteDesc: this.$t('home.metaTitle')
    })
  },
  computed: {
    component() {
      return this.$device.standalone
        ? 'delivery-app-layout'
        : 'layout'
    }
  }
}

import { make } from 'vuex-pathify'

const state = () => ({
  inZone: null,
  loginRedirect: false,
  order: {
    status: null,
    time: 0,
    token: null
  }
})

const actions   = make.actions(state)
const mutations = make.mutations(state)
const getters   = {
  ...make.getters(state)
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}

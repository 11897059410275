import Vue from 'vue'
import propertyQuery from '/opt/build/repo/node_modules/@evince/delivery/lib/graphql/queries/Property.gql'
import pip from '@turf/boolean-point-in-polygon'

import { dig } from 'vessel/utils'
import { watch, computed } from 'vue'

export default async function({ app, store }, inject) {
  const options = {"propertyQuery":"\u002Fopt\u002Fbuild\u002Frepo\u002Fnode_modules\u002F@evince\u002Fdelivery\u002Flib\u002Fgraphql\u002Fqueries\u002FProperty.gql","shoppingCart":{"continuePath":"\u002Fdelivery"},"headerImage":"\u002Fdelivery-image.jpg","acceptOrders":false,"openHours":{"from":"12:00","to":"17:30"},"validAreas":{"Κηφισιά":[14561,14562,14563,14564],"Νέα Ερυθραία":[14671],"Εκάλη":[14578]}}

  const { fullData } = await app.$graphql.query({
    clientId: 'eshop',
    query: propertyQuery
  })

  const settings = fullData.settings
  const place = fullData.place

  const delivery = Vue.observable({
    headerImage: options.headerImage,
    path: app.$pagePath(options.shoppingCart.continuePath),
    get currency() {
      return settings.general.defaultCurrency
    },
    get minimumAmount() {
      const value = settings.delivery.minimumAmount

      return {
        value,
        formatted: this.formatMoney(value)
      }
    },
    get itemsTotal() {
      const data = store.get('shoppingCart/data@itemsTotal')
      const value = data ? data.value : 0

      return {
        value,
        formatted: data ? data.formatted : null
      }
    },
    get minimumReached() {
      return this.itemsTotal.value >= this.minimumAmount.value
    },
    get totalDiff() {
      const value = this.minimumAmount.value - this.itemsTotal.value

      return {
        value,
        formatted: this.formatMoney(value)
      }
    },
    get user() {
      return this.$user || { email: store.state.shoppingCart.data.email }
    },
    get address() {
      const address = store.get('shoppingCart/data@billing')
      return dig(address, 'shippingAddress', 'address1')
    },
    get geoRange() {
      return place.geoRange
    },
    get inZone() {
      return store.get('delivery/inZone')
    },
    get hasCoords() {
      const address = store.get('shoppingCart/data@billing.shippingAddress')
      return Boolean(dig(address, 'latitude') && dig(address, 'longitude'))
    },
    get canCheckout() {
      return this.isOpen
    },
    get orderState() {
      return store.get('delivery/order')
    },
    get isOpen() {
      return app.$schedule.open
    },
    get loginRedirect() {
      return store.get('delivery/loginRedirect')
    },
    set loginRedirect(value) {
      store.set('delivery/loginRedirect', value)
    },
    matchPoint(coords) {
      if (!coords || !this.geoRange) return false

      const point = [coords.lng, coords.lat]
      return this.geoRange.features.some(poly => pip(point, poly))
    },
    formatMoney(amount) {
      const iso = app.i18n.locale

      return new Intl.NumberFormat(iso, { style: 'currency', currency: this.currency })
        .format(amount)
    }
  })

  const coordinates = computed(() => {
    const address = store.get('shoppingCart/data@billing.shippingAddress')

    return {
      lat: dig(address, 'latitude'),
      lng: dig(address, 'longitude')
    }
  })

  const onCoordsChange = (value) => {
    const match = delivery.matchPoint(value)
    store.set('delivery/inZone', match)
  }

  watch(coordinates, onCoordsChange, { immediate: true, deep: true })

  inject('delivery', delivery)
}

import { Loader } from '@googlemaps/js-api-loader'
import { compact, isBlank, toArray } from 'vessel/utils'

let google = null
let places = null
let geocode = null

const normalizeGeocode = (raw) => {
  const getValue = (type) => {
    const value = raw.address_components.find(item => item.types.includes(type))
    return value ? value.long_name : null
  }

  const route  = getValue('route')
  const number = getValue('street_number')

  return {
    lat:     raw.geometry.location.lat(),
    lng:     raw.geometry.location.lng(),
    street:  route,
    number:  number,
    postal:  getValue('postal_code'),
    city:    getValue('locality'),
    country: getValue('country'),
    address: compact([route, number]).join(' ')
  }
}

const normalizeSearch = (item) => ({
  address:       item.description,
  mainText:      item.structured_formatting.main_text,
  secondaryText: item.structured_formatting.secondary_text
})

export default (ctx, inject) => {
  const loader = new Loader({
    apiKey: process.env.GOOGLE_MAPS_API_KEY,
    version: 'beta',
    libraries: ['places', 'geocoding']
  })

  const loadGoogle = async () => {
    if (google == null) {
      google = await loader.load()
    }
  }

  const loadPlaces = async () => {
    await loadGoogle()

    if (places == null) {
      places = new google.maps.places.AutocompleteService()
    }
  }

  const loadGeocode = async () => {
    await loadGoogle()

    if (geocode == null) {
      geocode = new google.maps.Geocoder()
    }
  }

  inject('geocoder', {
    async search(address) {
      if (isBlank(address)) {
        return []
      }

      await loadPlaces()

      const options = {
        types: ['route', 'street_address'],
        componentRestrictions: { country: 'gr' },
        language: 'el',
        input: address
      }

      return new Promise((resolve) => {
        places.getPlacePredictions(options, (results) => {
          const items = compact(toArray(results))
          resolve(items.map(item => normalizeSearch(item)))
        })
      })
    },
    async geolookup(value) {
      await loadGeocode()

      const options = {
        address: value,
        language: 'el',
        region: 'GR'
      }

      return new Promise((resolve) => {
        geocode.geocode(options, (results) => {
          resolve(results.map(normalizeGeocode))
        })
      })
    },
    async reverse(lat, lng) {
      await loadGeocode()

      const options = {
        location: { lat, lng },
        language: 'el',
        region: 'GR'
      }

      return new Promise((resolve) => {
        geocode.geocode(options, (results) => {
          resolve(normalizeGeocode(results[0]))
        })
      })
    }
  })
}

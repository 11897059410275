export default async (ctx, inject) => {
  const options = {"el":{"icons":true,"address":"Αγίου Τρύφωνος 37, Κηφισιά","geoAddress":"COOKOS Kifisia","phone":"+30 210 8086 300","mobile":"+30 6948 054 584","businessHours":[{"days":"Καθημερινά","hours":"12:00 - 17:30"}]}}
  const attrs = Object.values(options)[0]

  const contact = {
    ...options,
    get details() {
      return this[ctx.app.i18n.vm.locale] || {}
    }
  }

  Object.keys(attrs).forEach(key => {
    Object.defineProperty(contact, key, {
      get() {
        return this.details[key]
      }
    })
  })

  inject('contact', contact)
}

import Vue from 'vue'
import propertyQuery from '/opt/build/repo/node_modules/@evince/delivery/lib/graphql/queries/Property.gql'

import { toArray } from 'vessel/utils'
import { useNow } from '@vueuse/core'

export default async function({ app }, inject) {
  const { fullData } = await app.$graphql.query({
    clientId: 'eshop',
    query: propertyQuery
  })

  const place = fullData.place

  const timeDiff = (from, to) => {
    const diff = to.getTime() - from.getTime()
    return Math.ceil(diff / 1000 / 60)
  }

  const timeBetween = (date, { from, to }) => {
    return date.getTime() >= from.getTime() &&
      date.getTime() < to.getTime()
  }

  const schedule = Vue.observable({
    now: useNow({ interval: 1000 }),
    get timeline () {
      return toArray(place.deliverySchedule).map(({ open, close }) => ({
        from: new Date(open.date),
        to: new Date(close.date)
      }))
    },
    get current() {
      return this.timeline.find(
        ({ from }) => from.getDate() == this.now.getDate()
      )
    },
    get open() {
      return this.current ? timeBetween(this.now, this.current) : false
    },
    get timeToOpen() {
      return this.current ? timeDiff(this.now, this.current.from) : 0
    },
    get timeToClose() {
      return this.current ? timeDiff(this.now, this.current.to) : 0
    },
    get state() {
      const states = [
        { text: 'opensIn', color: 'warning', mins: this.timeToOpen },
        { text: 'openNow', color: 'success' },
        { text: 'closingIn', color: 'warning', mins: this.timeToClose },
        { text: 'closedNow', color: 'danger' }
      ]

      let state = 'closedNow'

      if (this.open) {
        const soon = this.timeToClose > 0 && this.timeToClose <= 60
        state = soon ? 'closingIn' : 'openNow'
      } else {
        const soon = this.timeToOpen > 0 && this.timeToOpen <= 60
        state = soon ? 'opensIn' : 'closedNow'
      }

      return states.find(({ text }) => text == state)
    }
  })

  inject('schedule', schedule)
}

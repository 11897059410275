module.exports = {
  theme: {
    colors: {
      primary: '#1a1a1a',
      pink: '#f7ae83',
      black: '#1a1a1a',
      beige: '#f5f1e8'
    },
    fonts: {
      sans: [
        'Manrope',
        'Arial',
        'sans-serif'
      ],
      mono: [
        'Source code Pro',
        'Arial',
        'sans-serif'
      ]
    },
    typography: {
      body: {
        font: 'sans',
        leading: 'md',
        color: 'black',
        weight: 'normal',
        size: 'sm'
      },
      title: {
        font: 'sans',
        leading: 'sm',
        weight: 'black'
      },
      paragraph: {
        leading: 'lg',
        weight: 'light'
      },
      link: {
        color: 'primary'
      }
    }
  }
}

export const AuthSession = () => import('../../node_modules/@evince/auth/lib/packages/AuthSession.vue' /* webpackChunkName: "components/auth-session" */).then(c => wrapFunctional(c.default || c))
export const AuthSessionForm = () => import('../../node_modules/@evince/auth/lib/packages/AuthSessionForm.vue' /* webpackChunkName: "components/auth-session-form" */).then(c => wrapFunctional(c.default || c))
export const ScAddressLink = () => import('../../node_modules/@evince/site/lib/packages/AddressLink.vue' /* webpackChunkName: "components/sc-address-link" */).then(c => wrapFunctional(c.default || c))
export const ScBrowserList = () => import('../../node_modules/@evince/site/lib/packages/BrowserList.vue' /* webpackChunkName: "components/sc-browser-list" */).then(c => wrapFunctional(c.default || c))
export const ScBusinessHours = () => import('../../node_modules/@evince/site/lib/packages/BusinessHours.vue' /* webpackChunkName: "components/sc-business-hours" */).then(c => wrapFunctional(c.default || c))
export const ScCookieBar = () => import('../../node_modules/@evince/site/lib/packages/CookieBar.vue' /* webpackChunkName: "components/sc-cookie-bar" */).then(c => wrapFunctional(c.default || c))
export const ScDirectionsLink = () => import('../../node_modules/@evince/site/lib/packages/DirectionsLink.vue' /* webpackChunkName: "components/sc-directions-link" */).then(c => wrapFunctional(c.default || c))
export const ScDynamicForm = () => import('../../node_modules/@evince/site/lib/packages/DynamicForm.vue' /* webpackChunkName: "components/sc-dynamic-form" */).then(c => wrapFunctional(c.default || c))
export const ScEmailLink = () => import('../../node_modules/@evince/site/lib/packages/EmailLink.vue' /* webpackChunkName: "components/sc-email-link" */).then(c => wrapFunctional(c.default || c))
export const ScFaxLink = () => import('../../node_modules/@evince/site/lib/packages/FaxLink.vue' /* webpackChunkName: "components/sc-fax-link" */).then(c => wrapFunctional(c.default || c))
export const ScLangSwitcher = () => import('../../node_modules/@evince/site/lib/packages/LangSwitcher.vue' /* webpackChunkName: "components/sc-lang-switcher" */).then(c => wrapFunctional(c.default || c))
export const ScMainNav = () => import('../../node_modules/@evince/site/lib/packages/MainNav.vue' /* webpackChunkName: "components/sc-main-nav" */).then(c => wrapFunctional(c.default || c))
export const ScMobileLink = () => import('../../node_modules/@evince/site/lib/packages/MobileLink.vue' /* webpackChunkName: "components/sc-mobile-link" */).then(c => wrapFunctional(c.default || c))
export const ScMobileMenu = () => import('../../node_modules/@evince/site/lib/packages/MobileMenu.vue' /* webpackChunkName: "components/sc-mobile-menu" */).then(c => wrapFunctional(c.default || c))
export const ScNewsletterForm = () => import('../../node_modules/@evince/site/lib/packages/NewsletterForm.vue' /* webpackChunkName: "components/sc-newsletter-form" */).then(c => wrapFunctional(c.default || c))
export const ScPhoneLink = () => import('../../node_modules/@evince/site/lib/packages/PhoneLink.vue' /* webpackChunkName: "components/sc-phone-link" */).then(c => wrapFunctional(c.default || c))
export const ScSocialIcons = () => import('../../node_modules/@evince/site/lib/packages/SocialIcons.vue' /* webpackChunkName: "components/sc-social-icons" */).then(c => wrapFunctional(c.default || c))
export const ScViberLink = () => import('../../node_modules/@evince/site/lib/packages/ViberLink.vue' /* webpackChunkName: "components/sc-viber-link" */).then(c => wrapFunctional(c.default || c))
export const ScWhatsappLink = () => import('../../node_modules/@evince/site/lib/packages/WhatsappLink.vue' /* webpackChunkName: "components/sc-whatsapp-link" */).then(c => wrapFunctional(c.default || c))
export const ScContactButton = () => import('../../node_modules/@evince/site/lib/packages/contact/Button.vue' /* webpackChunkName: "components/sc-contact-button" */).then(c => wrapFunctional(c.default || c))
export const ScContactDetails = () => import('../../node_modules/@evince/site/lib/packages/contact/Details.vue' /* webpackChunkName: "components/sc-contact-details" */).then(c => wrapFunctional(c.default || c))
export const ScContactForm = () => import('../../node_modules/@evince/site/lib/packages/contact/Form.vue' /* webpackChunkName: "components/sc-contact-form" */).then(c => wrapFunctional(c.default || c))
export const PageContact = () => import('../../node_modules/@evince/site/lib/templates/contact.vue' /* webpackChunkName: "components/page-contact" */).then(c => wrapFunctional(c.default || c))
export const PageRequest = () => import('../../node_modules/@evince/site/lib/templates/request.vue' /* webpackChunkName: "components/page-request" */).then(c => wrapFunctional(c.default || c))
export const EshopCart = () => import('../../node_modules/@evince/eshop/lib/packages/Cart.vue' /* webpackChunkName: "components/eshop-cart" */).then(c => wrapFunctional(c.default || c))
export const EshopCartAdd = () => import('../../node_modules/@evince/eshop/lib/packages/CartAdd.vue' /* webpackChunkName: "components/eshop-cart-add" */).then(c => wrapFunctional(c.default || c))
export const EshopCartAddButton = () => import('../../node_modules/@evince/eshop/lib/packages/CartAddButton.vue' /* webpackChunkName: "components/eshop-cart-add-button" */).then(c => wrapFunctional(c.default || c))
export const EshopCartAddForm = () => import('../../node_modules/@evince/eshop/lib/packages/CartAddForm.vue' /* webpackChunkName: "components/eshop-cart-add-form" */).then(c => wrapFunctional(c.default || c))
export const EshopCartDrawer = () => import('../../node_modules/@evince/eshop/lib/packages/CartDrawer.vue' /* webpackChunkName: "components/eshop-cart-drawer" */).then(c => wrapFunctional(c.default || c))
export const EshopCartRemove = () => import('../../node_modules/@evince/eshop/lib/packages/CartRemove.vue' /* webpackChunkName: "components/eshop-cart-remove" */).then(c => wrapFunctional(c.default || c))
export const EshopCartToggle = () => import('../../node_modules/@evince/eshop/lib/packages/CartToggle.vue' /* webpackChunkName: "components/eshop-cart-toggle" */).then(c => wrapFunctional(c.default || c))
export const DeliveryAddressInput = () => import('../../node_modules/@evince/delivery/lib/packages/AddressInput.vue' /* webpackChunkName: "components/delivery-address-input" */).then(c => wrapFunctional(c.default || c))
export const DeliveryAppLayout = () => import('../../node_modules/@evince/delivery/lib/packages/AppLayout.vue' /* webpackChunkName: "components/delivery-app-layout" */).then(c => wrapFunctional(c.default || c))
export const DeliveryAuthModal = () => import('../../node_modules/@evince/delivery/lib/packages/AuthModal.vue' /* webpackChunkName: "components/delivery-auth-modal" */).then(c => wrapFunctional(c.default || c))
export const DeliveryAuthSession = () => import('../../node_modules/@evince/delivery/lib/packages/AuthSession.vue' /* webpackChunkName: "components/delivery-auth-session" */).then(c => wrapFunctional(c.default || c))
export const DeliveryCartToggle = () => import('../../node_modules/@evince/delivery/lib/packages/CartToggle.vue' /* webpackChunkName: "components/delivery-cart-toggle" */).then(c => wrapFunctional(c.default || c))
export const ScMutationForm = () => import('../../node_modules/@evince/core/lib/components/MutationForm.vue' /* webpackChunkName: "components/sc-mutation-form" */).then(c => wrapFunctional(c.default || c))
export const ScCountrySelect = () => import('../../node_modules/@evince/core/lib/components/country/Select.vue' /* webpackChunkName: "components/sc-country-select" */).then(c => wrapFunctional(c.default || c))
export const ScLanguageSelect = () => import('../../node_modules/@evince/core/lib/components/language/Select.vue' /* webpackChunkName: "components/sc-language-select" */).then(c => wrapFunctional(c.default || c))
export const ErrorDetails = () => import('../../node_modules/@evince/core/lib/packages/ErrorDetails.vue' /* webpackChunkName: "components/error-details" */).then(c => wrapFunctional(c.default || c))
export const PwaInstall = () => import('../../node_modules/@evince/core/lib/packages/PwaInstall.vue' /* webpackChunkName: "components/pwa-install" */).then(c => wrapFunctional(c.default || c))
export const PwaPrompt = () => import('../../node_modules/@evince/core/lib/packages/PwaPrompt.vue' /* webpackChunkName: "components/pwa-prompt" */).then(c => wrapFunctional(c.default || c))
export const StateLabel = () => import('../../node_modules/@evince/core/lib/packages/StateLabel.vue' /* webpackChunkName: "components/state-label" */).then(c => wrapFunctional(c.default || c))
export const GraphqlMutation = () => import('../../node_modules/@evince/core/lib/packages/graphql/Mutation.vue' /* webpackChunkName: "components/graphql-mutation" */).then(c => wrapFunctional(c.default || c))
export const GraphqlQuery = () => import('../../node_modules/@evince/core/lib/packages/graphql/Query.vue' /* webpackChunkName: "components/graphql-query" */).then(c => wrapFunctional(c.default || c))
export const AddressButton = () => import('../../components/AddressButton.vue' /* webpackChunkName: "components/address-button" */).then(c => wrapFunctional(c.default || c))
export const CallButton = () => import('../../components/CallButton.vue' /* webpackChunkName: "components/call-button" */).then(c => wrapFunctional(c.default || c))
export const ContentBox = () => import('../../components/ContentBox.vue' /* webpackChunkName: "components/content-box" */).then(c => wrapFunctional(c.default || c))
export const Logo = () => import('../../components/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const NewsletterSubscribe = () => import('../../components/NewsletterSubscribe.vue' /* webpackChunkName: "components/newsletter-subscribe" */).then(c => wrapFunctional(c.default || c))
export const PageTitle = () => import('../../components/PageTitle.vue' /* webpackChunkName: "components/page-title" */).then(c => wrapFunctional(c.default || c))
export const SpecialMenuLink = () => import('../../components/SpecialMenuLink.vue' /* webpackChunkName: "components/special-menu-link" */).then(c => wrapFunctional(c.default || c))
export const UserSession = () => import('../../components/UserSession.vue' /* webpackChunkName: "components/user-session" */).then(c => wrapFunctional(c.default || c))
export const CategoryCard = () => import('../../components/category/CategoryCard.vue' /* webpackChunkName: "components/category-card" */).then(c => wrapFunctional(c.default || c))
export const CategoryCardLoading = () => import('../../components/category/CategoryCardLoading.vue' /* webpackChunkName: "components/category-card-loading" */).then(c => wrapFunctional(c.default || c))
export const Copyright = () => import('../../components/layout/Copyright.vue' /* webpackChunkName: "components/copyright" */).then(c => wrapFunctional(c.default || c))
export const FooterColumn = () => import('../../components/layout/FooterColumn.vue' /* webpackChunkName: "components/footer-column" */).then(c => wrapFunctional(c.default || c))
export const FooterColumns = () => import('../../components/layout/FooterColumns.vue' /* webpackChunkName: "components/footer-columns" */).then(c => wrapFunctional(c.default || c))
export const HomeHeader = () => import('../../components/layout/HomeHeader.vue' /* webpackChunkName: "components/home-header" */).then(c => wrapFunctional(c.default || c))
export const ImageHeader = () => import('../../components/layout/ImageHeader.vue' /* webpackChunkName: "components/image-header" */).then(c => wrapFunctional(c.default || c))
export const Layout = () => import('../../components/layout/Layout.vue' /* webpackChunkName: "components/layout" */).then(c => wrapFunctional(c.default || c))
export const MenuActions = () => import('../../components/layout/MenuActions.vue' /* webpackChunkName: "components/menu-actions" */).then(c => wrapFunctional(c.default || c))
export const MenuHeader = () => import('../../components/layout/MenuHeader.vue' /* webpackChunkName: "components/menu-header" */).then(c => wrapFunctional(c.default || c))
export const TopBar = () => import('../../components/layout/TopBar.vue' /* webpackChunkName: "components/top-bar" */).then(c => wrapFunctional(c.default || c))
export const MenuTabs = () => import('../../components/orders/MenuTabs.vue' /* webpackChunkName: "components/menu-tabs" */).then(c => wrapFunctional(c.default || c))
export const OrderLayout = () => import('../../components/orders/OrderLayout.vue' /* webpackChunkName: "components/order-layout" */).then(c => wrapFunctional(c.default || c))
export const TypeFilter = () => import('../../components/orders/TypeFilter.vue' /* webpackChunkName: "components/type-filter" */).then(c => wrapFunctional(c.default || c))
export const ProductCard = () => import('../../components/products/ProductCard.vue' /* webpackChunkName: "components/product-card" */).then(c => wrapFunctional(c.default || c))
export const ProductCardLoading = () => import('../../components/products/ProductCardLoading.vue' /* webpackChunkName: "components/product-card-loading" */).then(c => wrapFunctional(c.default || c))
export const ProductPrice = () => import('../../components/products/ProductPrice.vue' /* webpackChunkName: "components/product-price" */).then(c => wrapFunctional(c.default || c))
export const ProductProperties = () => import('../../components/products/ProductProperties.vue' /* webpackChunkName: "components/product-properties" */).then(c => wrapFunctional(c.default || c))
export const ProductRelated = () => import('../../components/products/ProductRelated.vue' /* webpackChunkName: "components/product-related" */).then(c => wrapFunctional(c.default || c))
export const ProductTaxons = () => import('../../components/products/ProductTaxons.vue' /* webpackChunkName: "components/product-taxons" */).then(c => wrapFunctional(c.default || c))
export const ProductsList = () => import('../../components/products/ProductsList.vue' /* webpackChunkName: "components/products-list" */).then(c => wrapFunctional(c.default || c))
export const AboutSection = () => import('../../components/sections/AboutSection.vue' /* webpackChunkName: "components/about-section" */).then(c => wrapFunctional(c.default || c))
export const ApplicationSection = () => import('../../components/sections/ApplicationSection.vue' /* webpackChunkName: "components/application-section" */).then(c => wrapFunctional(c.default || c))
export const CateringSection = () => import('../../components/sections/CateringSection.vue' /* webpackChunkName: "components/catering-section" */).then(c => wrapFunctional(c.default || c))
export const FeaturesSection = () => import('../../components/sections/FeaturesSection.vue' /* webpackChunkName: "components/features-section" */).then(c => wrapFunctional(c.default || c))
export const HomeContent = () => import('../../components/sections/HomeContent.vue' /* webpackChunkName: "components/home-content" */).then(c => wrapFunctional(c.default || c))
export const MenuInfo = () => import('../../components/sections/MenuInfo.vue' /* webpackChunkName: "components/menu-info" */).then(c => wrapFunctional(c.default || c))
export const MenuSection = () => import('../../components/sections/MenuSection.vue' /* webpackChunkName: "components/menu-section" */).then(c => wrapFunctional(c.default || c))
export const NewsletterSection = () => import('../../components/sections/NewsletterSection.vue' /* webpackChunkName: "components/newsletter-section" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
